<script setup lang="ts">
import { fetchLegacyEvents, formatDateStr } from '@shared/utils'
import type { LegacyListEvent } from '@shared/types'

const props = defineProps<{
  type: 'live' | 'upcoming'
}>()
const eventsReady = ref(false)
const events = ref<LegacyListEvent[]>([])
const heading = ref('')

if (props.type === 'live') {
  events.value = await fetchLegacyEvents('live')
  heading.value = 'Live Events'
} else if (props.type === 'upcoming') {
  events.value = await fetchLegacyEvents('upcoming')
  heading.value = 'Upcoming Events'
}
eventsReady.value = true

function eventPath(event: LegacyListEvent) {
  return `/${event.host.slug}/${event.slug}/`
}
</script>
<template>
  <div v-if="eventsReady && events.length > 0" class="pb-10">
    <h3>{{ heading }}</h3>
    <ul>
      <li v-for="event in events" :key="event.id">
        <div class="flex flex-row py-2">
          <div class="flex w-10 flex-nowrap">
            <img :src="event.host.photo" alt="Host logo" class="w-10 h-10 rounded-full" />
          </div>
          <div class="flex flex-col justify-center flex-1 pl-2 md:pl-4">
            <div>
              <a :href="eventPath(event)">{{ event.title }}</a>
            </div>
            <div class="text-xs opacity-50">{{ formatDateStr(event.scheduledTime) }} by {{ event.host.name }}</div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <slot v-else />
</template>
