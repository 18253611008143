import { prefetchImages } from '@shared/utils'

if (typeof window !== 'undefined') {
  window.addEventListener('load', () => {
    requestIdleCallback(() => {
      prefetchImages([
        'https://next.slipmat.io/img/live_bg_alt_0_v0.webp',
        'https://next.slipmat.io/img/live_bg_alt_1_v0.webp',
        'https://next.slipmat.io/img/live_bg_alt_2_v0.webp',
        'https://next.slipmat.io/img/live_bg_alt_2_v0.webp',
        'https://next.slipmat.io/img/live_bg_alt_3_v0.webp',
        'https://next.slipmat.io/img/live_bg_alt_4_v0.webp',
        'https://next.slipmat.io/img/live_bg_alt_5_v0.webp',
        'https://next.slipmat.io/img/live_bg_alt_6_v0.webp',
      ])
    })
  })
}
