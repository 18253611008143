<script setup lang="ts">
const keyUrl = import.meta.env.DEV ? 'http://localhost:8000/test/legacy-key/' : 'https://slipmat.io/key/'
const loginUrl = import.meta.env.DEV ? 'http://localhost:5174/login/' : 'https://account.slipmat.io/login/'
const isReady = ref(false)
const slipmatKey = ref<string | null>(null)

const isBot = () => {
  try {
    const hasNormalBrowserFeatures = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || !navigator.webdriver

    return !hasNormalBrowserFeatures
  } catch {
    return true
  }
}

async function getKey() {
  try {
    const response = await fetch(keyUrl, {
      credentials: 'include',
    })
    const html = await response.text()
    const match = html.match(/<p class="code">(.*?)<\/p>/)
    slipmatKey.value = match ? match[1] : null
    if (slipmatKey.value) {
      logger.debug('✅ Slipmat key found and set')
      localStorage.setItem('slipmatKey', slipmatKey.value)
    }
  } catch (error) {
    slipmatKey.value = null
    logger.warn('Error fetching key: ', error)
  }
  isReady.value = true
}

onMounted(async () => {
  if (!isBot()) {
    await getKey()
  } else {
    logger.warn('Not checking Slipmat profile.')
  }
})
</script>
<template>
  <div v-if="isReady" class="hidden xl:block">
    <p class="mt-8 text-center">
      <template v-if="slipmatKey">
        <a :href="loginUrl" class="font-bold text-orange-600">🚀 &nbsp;Early Access</a>
      </template>
      <span class="hidden">
        <a
          href="https://slipmat.io/m/logout-damnit/"
          class="inline-block pt-8 font-semibold text-blue-500 hover:underline"
          >Log in at Slipmat.io</a
        >
        and come back here for Early Access.
      </span>
    </p>
  </div>
</template>
